._404-page {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

input {
  outline: none;
}

html, body {
  background-color: #fff;
  height: 100%;
  margin: 0;
}

.multiple-select {
  outline: none;
  position: relative;
}

.select-input-wrapper {
  box-sizing: border-box;
  outline: none;
  width: 100%;
  max-width: 100%;
  line-height: 24px;
  display: block;
}

.select-input-wrapper .select-input-content {
  border: 1px solid #dee2e4;
  border-radius: 3px;
  width: 100%;
  padding: 3px;
}

.select-input-wrapper .select-input {
  background-color: #0000;
  border: 0;
  width: 100%;
}

.select-input-wrapper .selected {
  background-color: #a9a9a9;
  border-radius: 8px;
  outline: none;
  margin-right: 7px;
  padding: 0 4px;
  font-size: 12px;
  display: inline-block;
}

.select-input-wrapper .selected .x {
  cursor: pointer;
  background-color: #c42525;
  border-radius: 50%;
  margin: 5px;
  display: inline;
}

.select-input-wrapper .selected .x span {
  padding: 2px;
}

.select-content-wrapper.none {
  display: none;
}

.select-content-wrapper {
  z-index: 1;
  width: 100%;
  max-height: 300px;
  position: absolute;
  top: 100%;
  overflow-y: auto;
}

.select-content-wrapper .select-content {
  background-color: #fff;
  border: 1px solid #000;
  margin: 0 10px;
  padding-left: 0;
}

.select-content-wrapper .select-content .select-content-item:not(:last-child) {
  border-bottom: 1px solid #000;
}

.select-content-wrapper .select-content .select-content-item {
  padding: 0 5px;
}

.select-content-wrapper .select-content .select-content-item label {
  box-sizing: border-box;
  color: #666;
  cursor: pointer;
  background-color: #fff;
  align-items: center;
  margin-bottom: 5px;
  padding: 2px 0;
  display: flex;
}

.select-content-wrapper .select-content .select-content-item span {
  vertical-align: middle;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  padding: 2px 5px;
  display: inline-block;
}

@keyframes swing {
  0% {
    transform: rotate(0);
  }

  10% {
    transform: rotate(10deg);
  }

  30% {
    transform: rotate(0);
  }

  40% {
    transform: rotate(-10deg);
  }

  50% {
    transform: rotate(0);
  }

  60% {
    transform: rotate(5deg);
  }

  70% {
    transform: rotate(0);
  }

  80% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes sonar {
  0% {
    opacity: 1;
    transform: scale(.9);
  }

  100% {
    opacity: 0;
    transform: scale(2);
  }
}

body {
  font-size: .9rem;
}

.page-wrapper .sidebar-wrapper, .sidebar-wrapper .sidebar-brand > a, .sidebar-wrapper .sidebar-dropdown > a:after, .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before, .sidebar-wrapper ul li a i, .page-wrapper .page-content, input.search-menu, .input-group-text, .sidebar-wrapper .sidebar-menu ul li a, #show-sidebar, #close-sidebar {
  -o-transition: all .3s ease;
  transition: all .3s;
}

.page-wrapper {
  height: 100vh;
}

.page-wrapper .theme {
  border-radius: 4px;
  width: 40px;
  height: 40px;
  margin: 2px;
  display: inline-block;
}

.page-wrapper .theme.chiller-theme {
  background: #1e2229;
}

.page-wrapper.toggled .sidebar-wrapper {
  left: 0;
}

@media screen and (width >= 768px) {
  .page-wrapper.toggled .page-content {
    padding-left: 260px;
  }
}

#show-sidebar {
  border-radius: 0 4px 4px 0;
  width: 35px;
  transition-delay: .3s;
  position: fixed;
  top: 10px;
  left: 0;
}

.page-wrapper.toggled #show-sidebar {
  left: -40px;
}

.sidebar-wrapper {
  z-index: 999;
  width: 260px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: -300px;
}

.sidebar-wrapper ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.sidebar-wrapper a {
  text-decoration: none;
}

.sidebar-content {
  height: calc(100% - 30px);
  max-height: calc(100% - 30px);
  position: relative;
  overflow-y: auto;
}

.sidebar-content.desktop {
  overflow-y: hidden;
}

.sidebar-wrapper .sidebar-brand {
  align-items: center;
  padding: 10px 20px;
  display: flex;
}

.sidebar-wrapper .sidebar-brand > a {
  text-transform: uppercase;
  flex-grow: 1;
  font-weight: bold;
}

.sidebar-wrapper .sidebar-brand #close-sidebar {
  cursor: pointer;
  font-size: 20px;
}

.sidebar-wrapper .sidebar-header {
  padding: 20px;
  overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic {
  float: left;
  border-radius: 12px;
  width: 60px;
  max-height: 60px;
  margin-right: 15px;
  padding: 2px;
  overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic img {
  object-fit: cover;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 100%;
}

.sidebar-wrapper .sidebar-header .user-info {
  float: left;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 75px);
  height: 60px;
  display: flex;
}

.sidebar-wrapper .sidebar-header .user-info > span {
  display: block;
}

.sidebar-wrapper .sidebar-header .user-info .user-role {
  font-size: 12px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status {
  margin-top: 4px;
  font-size: 11px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status i {
  color: #5cb85c;
  margin-right: 4px;
  font-size: 8px;
}

.sidebar-wrapper .sidebar-search > div {
  padding: 10px 20px;
}

.sidebar-wrapper .sidebar-menu {
  padding-bottom: 10px;
}

.sidebar-wrapper .sidebar-menu .header-menu span {
  padding: 15px 20px 5px;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
}

.sidebar-wrapper .sidebar-menu ul li a {
  width: 100%;
  padding: 8px 30px 8px 20px;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.sidebar-wrapper .sidebar-menu ul li a i {
  text-align: center;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  font-size: 12px;
  line-height: 30px;
}

.sidebar-wrapper .sidebar-menu ul li a:hover > i:before {
  animation: .5s ease-in-out alternate swing;
  display: inline-block;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after {
  content: "";
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: none;
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  display: inline-block;
  position: absolute;
  top: 14px;
  right: 15px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
  padding: 5px 0;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
  padding-left: 25px;
  font-size: 13px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before {
  content: "";
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
  font-family: "Font Awesome 5 Free";
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  display: inline-block;
}

.sidebar-wrapper .sidebar-menu ul li a span.label, .sidebar-wrapper .sidebar-menu ul li a span.badge {
  float: right;
  margin-top: 8px;
  margin-left: 5px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge, .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
  float: right;
  margin-top: 0;
}

.sidebar-wrapper .sidebar-menu .sidebar-submenu {
  display: none;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
  right: 17px;
  transform: rotate(90deg);
}

.sidebar-footer {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
}

.sidebar-footer > a {
  text-align: center;
  flex-grow: 1;
  height: 30px;
  line-height: 30px;
  position: relative;
}

.sidebar-footer > a .notification {
  position: absolute;
  top: 0;
}

.badge-sonar {
  background: #980303;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  display: inline-block;
  position: absolute;
  top: 0;
}

.badge-sonar:after {
  content: "";
  opacity: 0;
  border: 2px solid #980303;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: 1.5s infinite sonar;
  position: absolute;
  top: 0;
  left: 0;
}

.page-wrapper .page-content {
  width: 100%;
  padding-left: 0;
  display: inline-block;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: #525965;
  border: 0 #fff;
  border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
  background: #525965;
}

::-webkit-scrollbar-thumb:active {
  background: #525965;
}

::-webkit-scrollbar-track {
  background: none;
  border: 0 #fff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: none;
}

::-webkit-scrollbar-track:active {
  background: none;
}

::-webkit-scrollbar-corner {
  background: none;
}

.chiller-theme-bg, .chiller-theme .sidebar-wrapper {
  background: #32323a;
}

.chiller-theme .sidebar-wrapper .sidebar-header, .chiller-theme .sidebar-wrapper .sidebar-search, .chiller-theme .sidebar-wrapper .sidebar-menu {
  border-top: 1px solid #4a4a52;
}

input.search-menu, .input-group-text {
  box-shadow: none;
  border-color: #0000;
}

.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-role, .chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-status, input.search-menu, .input-group-text, .chiller-theme .sidebar-wrapper .sidebar-brand > a, .chiller-theme .sidebar-wrapper .sidebar-menu ul li a, .chiller-theme .sidebar-footer > a, .chiller-theme .sidebar-wrapper .sidebar-menu ul li:hover > a, .chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a, .chiller-theme .sidebar-wrapper .sidebar-header .user-info, .chiller-theme .sidebar-wrapper .sidebar-brand > a:hover, .chiller-theme .sidebar-footer > a:hover i {
  color: #b0b3b8;
}

.sidebar-footer-a:hover .fa-power-off.sidebar-footer-a-i {
  color: #dadada !important;
}

.page-wrapper.chiller-theme.toggled #close-sidebar {
  color: #fff;
}

.page-wrapper.chiller-theme.toggled #close-sidebar:hover {
  color: #000;
}

.chiller-theme .sidebar-wrapper ul li:hover a i, .chiller-theme .sidebar-wrapper .sidebar-dropdown .sidebar-submenu li a:hover:before, input.search-menu:focus + span, .chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i {
  color: #16c7ff;
  text-shadow: 0 0 10px #16c7ff80;
}

input.search-menu:focus {
  color: #000 !important;
  border-color: #0000 !important;
  box-shadow: 0 0 0 .2rem #0000 !important;
}

input.search-menu, .input-group-text {
  background: #32323a !important;
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li a i, .chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown div, input.search-menu, .input-group-text {
  background: #4a4a52;
}

.chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
  color: #b0b3b8;
}

.chiller-theme .sidebar-footer {
  background: #32323a;
  border-top: 1px solid #464a52;
  box-shadow: 0 -1px 5px #32323a;
}

.chiller-theme .sidebar-footer > a:first-child {
  border-left: none;
}

.chiller-theme .sidebar-footer > a:last-child {
  border-right: none;
}

.sidebar-footer-a {
  float: right;
  display: flex;
}

.sidebar-footer-a-i {
  margin: auto;
}

.page-wrapper:not(.toggled) .app > div {
  padding: 0 40px;
}

.cursor-pointer {
  cursor: pointer;
}

.app {
  overflow: overlay;
  color: #000;
  flex-direction: column;
  height: 100vh;
  display: flex;
}

.app-content {
  flex-grow: 3;
}

.registration-page {
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

.registration-form {
  border: 1px solid #000;
  border-radius: 8px;
  padding: 3em;
}

.login-page {
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

.login-form {
  border: 1px solid #000;
  border-radius: 8px;
  padding: 3em;
}

.tab-content .card {
  border-radius: 0;
}

.tab-content .card .card-header {
  background-color: #f6f6f6;
  border-radius: 0;
  padding: 15px 16px;
}

.tab-content .card .card-header h5 {
  margin: 0;
}

.tab-content .card .card-header h5 button {
  color: #000000de;
  text-align: left;
  white-space: normal;
  border: 0;
  width: 100%;
  padding: 0;
  font-weight: 700;
  display: block;
}

.tab-content .card .card-header h5 button:hover, .tab-content .card .card-header h5 button:focus, .tab-content .card .card-header h5 button:active, .tab-content .card .card-header h5 button:hover:active {
  text-decoration: none;
}

.tab-content .card .card-body p {
  color: #616161;
}

.tab-content .card .card-body p:last-of-type {
  margin: 0;
}

.accordion > .card:not(:first-child) {
  border-top: 0;
}

.collapse.show .card-body {
  border-bottom: 1px solid #00000020;
}

.fields-handle {
  background: #000;
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.th-sm {
  cursor: pointer;
  position: relative;
  padding-right: 30px !important;
}

.th-sm.up:before {
  opacity: 1;
}

.th-sm:before {
  opacity: .3;
  content: "";
  font-family: "Font Awesome 5 Free";
  font-size: 1rem;
  font-weight: 900;
  display: block;
  position: absolute;
  bottom: 1em;
  right: 1em;
}

.th-sm.down:after {
  opacity: 1;
}

.th-sm:after {
  opacity: .3;
  content: "";
  font-family: "Font Awesome 5 Free";
  font-size: 1rem;
  font-weight: 900;
  display: block;
  position: absolute;
  bottom: 1em;
  right: 16px;
}

.iamge-wrapper {
  z-index: 2000;
  top: 0;
  left: 0;
}

.image-background {
  opacity: .7;
  background-color: #fff;
}

.image-form {
  z-index: 2001;
  min-height: 70%;
  max-height: 80%;
  overflow-y: scroll;
}

.react-datepicker-wrapper {
  width: 100%;
}

.see-wrapper {
  z-index: 1000;
  top: 0;
  left: 0;
}

.see-background {
  opacity: .7;
  background-color: #fff;
}

.see-form {
  overflow-y: overlay;
  z-index: 1001;
  max-height: 80%;
}

.ticket-form {
  height: calc(100% - 55px);
}

.ticket-textarea {
  resize: none;
  width: 100%;
  height: 80px;
  padding: 5px;
}

.login-notification-textarea {
  width: 100%;
  min-height: 120px;
  padding: 5px;
}

.invisible-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-01 .switch {
  width: 60px;
  height: 34px;
  margin: 0 20px;
  display: inline-block;
  position: relative;
}

.switch-01 .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-01 .switch input:checked + .slider {
  background-color: #2196f3;
}

.switch-01 .switch input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.switch-01 .switch input:checked + .slider:before {
  transform: translateX(26px);
}

.switch-01 .slider {
  cursor: pointer;
  background-color: #ccc;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.switch-01 .slider:before {
  content: "";
  background-color: #fff;
  width: 26px;
  height: 26px;
  transition: all .4s;
  position: absolute;
  bottom: 4px;
  left: 4px;
}

.switch-01 .slider.round {
  border-radius: 34px;
}

.switch-01 .slider.round:before {
  border-radius: 50%;
}

.info-modal-wrapper {
  z-index: 1000;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.info-modal-wrapper .info-modal-bg {
  opacity: .6;
  background: #4c4c4c;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.info-modal-wrapper .info-modal {
  z-index: 1;
  background: #fff;
  border: 1px solid #000;
  border-radius: 8px;
  max-width: 60%;
  max-height: 60%;
  padding: 15px;
  opacity: 1 !important;
}

.info-modal-wrapper .info-modal .info-modal-header {
  grid-template-rows: auto;
  grid-template-columns: auto min-content;
  width: 100%;
  display: grid;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker__year-read-view--down-arrow:before, .react-datepicker__month-read-view--down-arrow:before, .react-datepicker__month-year-read-view--down-arrow:before {
  box-sizing: content-box;
  border: 8px solid #0000;
  width: 1px;
  height: 0;
  position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker__year-read-view--down-arrow:before, .react-datepicker__month-read-view--down-arrow:before, .react-datepicker__month-year-read-view--down-arrow:before {
  content: "";
  z-index: -1;
  border-width: 8px;
  border-bottom-color: #aeaeae;
  left: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  margin-top: -8px;
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-bottom-color: #aeaeae;
  top: -1px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  margin-bottom: -8px;
  bottom: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker__year-read-view--down-arrow:before, .react-datepicker__month-read-view--down-arrow:before, .react-datepicker__month-year-read-view--down-arrow:before {
  border-top-color: #fff;
  border-bottom: none;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker__year-read-view--down-arrow:before, .react-datepicker__month-read-view--down-arrow:before, .react-datepicker__month-year-read-view--down-arrow:before {
  border-top-color: #aeaeae;
  bottom: -1px;
}

.react-datepicker-wrapper {
  border: 0;
  padding: 0;
  display: inline-block;
}

.react-datepicker {
  color: #000;
  background-color: #fff;
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: .8rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time, .react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-right-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: .3rem;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: .3rem;
}

.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll {
  margin: 0 2px;
  display: inline-block;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: #000;
  margin-top: 0;
  font-size: .944rem;
  font-weight: bold;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  text-align: center;
  cursor: pointer;
  z-index: 1;
  text-indent: -999em;
  background: none;
  border: .45rem solid #0000;
  width: 10px;
  height: 10px;
  padding: 0;
  line-height: 1.7rem;
  position: absolute;
  top: 10px;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 10px;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  cursor: default;
  border-right-color: #e6e6e6;
}

.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 10px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 95px;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  cursor: default;
  border-left-color: #e6e6e6;
}

.react-datepicker__navigation--years {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
}

.react-datepicker__navigation--years-previous {
  border-top-color: #ccc;
  top: 4px;
}

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
  border-bottom-color: #ccc;
  top: -4px;
}

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__year-wrapper {
  flex-wrap: wrap;
  max-width: 180px;
  display: flex;
}

.react-datepicker__year .react-datepicker__year-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__month {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__input-time-container {
  clear: both;
  float: left;
  text-align: left;
  width: 100%;
  margin: 5px 0 10px 15px;
}

.react-datepicker__input-time-container .react-datepicker-time__caption, .react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  margin-left: 10px;
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}

.react-datepicker__time-container--with-today-button {
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  display: inline;
  position: absolute;
  top: 0;
  right: -72px;
}

.react-datepicker__time-container .react-datepicker__time {
  background: #fff;
  border-bottom-right-radius: .3rem;
  position: relative;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  text-align: center;
  border-bottom-right-radius: .3rem;
  width: 85px;
  margin: 0 auto;
  overflow-x: hidden;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  box-sizing: content-box;
  width: 100%;
  height: calc(195px + .85rem);
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  list-style: none;
  overflow-y: scroll;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  white-space: nowrap;
  height: 30px;
  padding: 5px 10px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  color: #fff;
  background-color: #216ba5;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: #0000;
}

.react-datepicker__week-number {
  color: #ccc;
  text-align: center;
  width: 1.7rem;
  margin: .166rem;
  line-height: 1.7rem;
  display: inline-block;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  background-color: #f0f0f0;
  border-radius: .3rem;
}

.react-datepicker__day-names, .react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: #000;
  text-align: center;
  width: 1.7rem;
  margin: .166rem;
  line-height: 1.7rem;
  display: inline-block;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range, .react-datepicker__quarter--selected, .react-datepicker__quarter--in-selecting-range, .react-datepicker__quarter--in-range {
  color: #fff;
  background-color: #216ba5;
  border-radius: .3rem;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover, .react-datepicker__quarter--selected:hover, .react-datepicker__quarter--in-selecting-range:hover, .react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__month--disabled, .react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover, .react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: #0000;
}

.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
  cursor: pointer;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  background-color: #f0f0f0;
  border-radius: .3rem;
}

.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted, .react-datepicker__year-text--highlighted {
  color: #fff;
  background-color: #3dcc4a;
  border-radius: .3rem;
}

.react-datepicker__day--highlighted:hover, .react-datepicker__month-text--highlighted:hover, .react-datepicker__quarter-text--highlighted:hover, .react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1, .react-datepicker__month-text--highlighted-custom-1, .react-datepicker__quarter-text--highlighted-custom-1, .react-datepicker__year-text--highlighted-custom-1 {
  color: #f0f;
}

.react-datepicker__day--highlighted-custom-2, .react-datepicker__month-text--highlighted-custom-2, .react-datepicker__quarter-text--highlighted-custom-2, .react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  color: #fff;
  background-color: #216ba5;
  border-radius: .3rem;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  color: #fff;
  background-color: #2a87d0;
  border-radius: .3rem;
}

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range {
  background-color: #216ba580;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range, .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range, .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range, .react-datepicker__month--selecting-range .react-datepicker__year-text--in-range {
  color: #000;
  background-color: #f0f0f0;
}

.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover, .react-datepicker__month-text--disabled:hover, .react-datepicker__quarter-text--disabled:hover, .react-datepicker__year-text--disabled:hover {
  background-color: #0000;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover, .react-datepicker__quarter-text.react-datepicker__month--selected:hover, .react-datepicker__quarter-text.react-datepicker__month--in-range:hover, .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover, .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}

.react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  width: 100%;
  display: inline-block;
  position: relative;
}

.react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view {
  border: 1px solid #0000;
  border-radius: .3rem;
}

.react-datepicker__year-read-view:hover, .react-datepicker__month-read-view:hover, .react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  float: right;
  border-width: .45rem;
  border-top-color: #ccc;
  margin-left: 20px;
  position: relative;
  top: 8px;
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
  z-index: 1;
  text-align: center;
  background-color: #f0f0f0;
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  width: 50%;
  position: absolute;
  top: 30px;
  left: 25%;
}

.react-datepicker__year-dropdown:hover, .react-datepicker__month-dropdown:hover, .react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable, .react-datepicker__month-dropdown--scrollable, .react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 20px;
  display: block;
}

.react-datepicker__year-option:first-of-type, .react-datepicker__month-option:first-of-type, .react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}

.react-datepicker__year-option:last-of-type, .react-datepicker__month-option:last-of-type, .react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  user-select: none;
  border-bottom-right-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected, .react-datepicker__month-option--selected, .react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  vertical-align: middle;
  background-color: #0000;
  border: 0;
  outline: 0;
  height: 100%;
  padding: 0 6px 0 0;
  display: table-cell;
  position: absolute;
  top: 0;
  right: 0;
}

.react-datepicker__close-icon:after {
  cursor: pointer;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  content: "×";
  background-color: #216ba5;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  display: table-cell;
}

.react-datepicker__today-button {
  cursor: pointer;
  text-align: center;
  clear: left;
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  padding: 5px 0;
  font-weight: bold;
}

.react-datepicker__portal {
  z-index: 2147483647;
  background-color: #000c;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (width <= 400px), (height <= 550px) {
  .react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation {
  border: .81rem solid #0000;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  cursor: default;
  border-right-color: #e6e6e6;
}

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  cursor: default;
  border-left-color: #e6e6e6;
}

.rc-pagination {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.rc-pagination ul, .rc-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.rc-pagination:after {
  clear: both;
  visibility: hidden;
  content: " ";
  height: 0;
  display: block;
  overflow: hidden;
}

.rc-pagination-total-text {
  vertical-align: middle;
  height: 28px;
  margin-right: 8px;
  line-height: 26px;
  display: inline-block;
}

.rc-pagination-item {
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: 0;
  min-width: 28px;
  height: 28px;
  margin-right: 8px;
  font-family: Arial;
  line-height: 26px;
  list-style: none;
  display: inline-block;
}

.rc-pagination-item a {
  color: #000000d9;
  padding: 0 6px;
  transition: none;
  display: block;
}

.rc-pagination-item a:hover {
  text-decoration: none;
}

.rc-pagination-item:focus, .rc-pagination-item:hover {
  border-color: #1890ff;
  transition: all .3s;
}

.rc-pagination-item:focus a, .rc-pagination-item:hover a {
  color: #1890ff;
}

.rc-pagination-item-active {
  background: #fff;
  border-color: #1890ff;
  font-weight: 500;
}

.rc-pagination-item-active a {
  color: #1890ff;
}

.rc-pagination-item-active:focus, .rc-pagination-item-active:hover {
  border-color: #40a9ff;
}

.rc-pagination-item-active:focus a, .rc-pagination-item-active:hover a {
  color: #40a9ff;
}

.rc-pagination-jump-prev, .rc-pagination-jump-next {
  outline: 0;
}

.rc-pagination-jump-prev button, .rc-pagination-jump-next button {
  cursor: pointer;
  color: #666;
  background: none;
  border: none;
}

.rc-pagination-jump-prev button:after, .rc-pagination-jump-next button:after {
  content: "•••";
  display: block;
}

.rc-pagination-prev, .rc-pagination-jump-prev, .rc-pagination-jump-next {
  margin-right: 8px;
}

.rc-pagination-prev, .rc-pagination-next, .rc-pagination-jump-prev, .rc-pagination-jump-next {
  color: #000000d9;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 2px;
  min-width: 28px;
  height: 28px;
  font-family: Arial;
  line-height: 28px;
  list-style: none;
  transition: all .3s;
  display: inline-block;
}

.rc-pagination-prev, .rc-pagination-next {
  outline: 0;
}

.rc-pagination-prev button, .rc-pagination-next button {
  color: #000000d9;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.rc-pagination-prev:hover button, .rc-pagination-next:hover button {
  border-color: #40a9ff;
}

.rc-pagination-prev .rc-pagination-item-link, .rc-pagination-next .rc-pagination-item-link {
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all .3s;
  display: block;
}

.rc-pagination-prev:focus .rc-pagination-item-link, .rc-pagination-next:focus .rc-pagination-item-link, .rc-pagination-prev:hover .rc-pagination-item-link, .rc-pagination-next:hover .rc-pagination-item-link {
  color: #1890ff;
  border-color: #1890ff;
}

.rc-pagination-prev button:after {
  content: "‹";
  display: block;
}

.rc-pagination-next button:after {
  content: "›";
  display: block;
}

.rc-pagination-disabled, .rc-pagination-disabled:hover, .rc-pagination-disabled:focus {
  cursor: not-allowed;
}

.rc-pagination-disabled .rc-pagination-item-link, .rc-pagination-disabled:hover .rc-pagination-item-link, .rc-pagination-disabled:focus .rc-pagination-item-link {
  color: #00000040;
  cursor: not-allowed;
  border-color: #d9d9d9;
}

.rc-pagination-slash {
  margin: 0 10px 0 5px;
}

.rc-pagination-options {
  vertical-align: middle;
  margin-left: 16px;
  display: inline-block;
}

@media (-ms-high-contrast: none) {
  .rc-pagination-options {
    vertical-align: top;
  }

  .rc-pagination-options ::-ms-backdrop {
    vertical-align: top;
  }
}

.rc-pagination-options-size-changer.rc-select {
  width: auto;
  margin-right: 8px;
  display: inline-block;
}

.rc-pagination-options-quick-jumper {
  vertical-align: top;
  height: 28px;
  line-height: 28px;
  display: inline-block;
}

.rc-pagination-options-quick-jumper input {
  width: 50px;
  margin: 0 8px;
}

.rc-pagination-simple .rc-pagination-prev, .rc-pagination-simple .rc-pagination-next {
  vertical-align: top;
  height: 24px;
  line-height: 24px;
}

.rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link, .rc-pagination-simple .rc-pagination-next .rc-pagination-item-link {
  background-color: #0000;
  border: 0;
  height: 24px;
}

.rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link:after, .rc-pagination-simple .rc-pagination-next .rc-pagination-item-link:after {
  height: 24px;
  line-height: 24px;
}

.rc-pagination-simple .rc-pagination-simple-pager {
  height: 24px;
  margin-right: 8px;
  display: inline-block;
}

.rc-pagination-simple .rc-pagination-simple-pager input {
  box-sizing: border-box;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  transition: border-color .3s;
}

.rc-pagination-simple .rc-pagination-simple-pager input:hover {
  border-color: #1890ff;
}

.rc-pagination.rc-pagination-disabled {
  cursor: not-allowed;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item {
  cursor: not-allowed;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item a {
  color: #00000040;
  cursor: not-allowed;
  background: none;
  border: none;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-active {
  background: #dbdbdb;
  border-color: #0000;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-active a {
  color: #fff;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-link {
  color: #00000040;
  cursor: not-allowed;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-link-icon {
  opacity: 0;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-ellipsis {
  opacity: 1;
}

@media only screen and (width <= 992px) {
  .rc-pagination-item-after-jump-prev, .rc-pagination-item-before-jump-next {
    display: none;
  }
}

@media only screen and (width <= 576px) {
  .rc-pagination-options {
    display: none;
  }
}
/*# sourceMappingURL=index.5bfdda6e.css.map */
