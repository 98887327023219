._404-page
  display: flex
  justify-content: center
  align-items: center
  height: 100%
    // width: 100%
  // text-align: center

input
  outline: none

html, body

  background-color: #fff
  height: 100%
  margin: 0

// multiple select
.multiple-select
  position: relative
  outline: none

.select-input-wrapper
  width: 100%
  display: block
  max-width: 100%
  box-sizing: border-box
  line-height: 24px
  outline: none

  .select-input-content
    width: 100%
    padding: 3px
    border-radius: 3px
    border: 1px solid rgb(222, 226, 228)

  .select-input
    border: 0
    width: 100%
    background-color: transparent

  .selected
    padding: 0px 4px
    border-radius: 8px
    background-color: darkgray
    font-size: 12px
    margin-right: 7px
    display: inline-block
    outline: none

    .x
      display: inline
      background-color: #c42525
      border-radius: 50%
      margin: 5px
      cursor: pointer

      span
        padding: 2px

.select-content-wrapper.none
  display: none

.select-content-wrapper
  max-height: 300px
  position: absolute
  top: 100%
  width: 100%
  z-index: 1
  overflow-y: auto

  .select-content
    margin: 0px
    padding-left: 0px
    margin: 0px 10px
    border: 1px solid #000
    background-color: #fff

    .select-content-item:not(:last-child)
      border-bottom: 1px solid #000

    .select-content-item
      padding: 0px 5px

      label
        margin-bottom: 5px
        padding: 2px 0px
        box-sizing: border-box
        background-color: rgb(255, 255, 255)
        color: rgb(102, 102, 102)
        cursor: pointer
        display: flex
        align-items: center

      span
        display: inline-block
        vertical-align: middle
        border-bottom-right-radius: 2px
        border-top-right-radius: 2px
        padding: 2px 5px

//sidebar
=keyframes($animation-name)
  @-webkit-keyframes #{$animation-name}
    @content
  @-moz-keyframes #{$animation-name}
    @content
  @keyframes #{$animation-name}
    @content

@keyframes swing
  0%
    transform: rotate(0deg)
  10%
    transform: rotate(10deg)
  30%
    transform: rotate(0deg)
  40%
    transform: rotate(-10deg)
  50%
    transform: rotate(0deg)
  60%
    transform: rotate(5deg)
  70%
    transform: rotate(0deg)
  80%
    transform: rotate(-5deg)
  100%
    transform: rotate(0deg)

@keyframes sonar
  0%
    transform: scale(0.9)
    opacity: 1

  100%
    transform: scale(2)
    opacity: 0

body
  font-size: 0.9rem

.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-dropdown > a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
input.search-menu,
.input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar
  -webkit-transition: all 0.3s ease
  -moz-transition: all 0.3s ease
  -ms-transition: all 0.3s ease
  -o-transition: all 0.3s ease
  transition: all 0.3s ease

  /*----------------page-wrapper----------------*/

.page-wrapper
  height: 100vh

.page-wrapper .theme
  width: 40px
  height: 40px
  display: inline-block
  border-radius: 4px
  margin: 2px

.page-wrapper .theme.chiller-theme
  background: #1e2229

  /*----------------toggeled sidebar----------------*/

.page-wrapper.toggled .sidebar-wrapper
  left: 0px

@media screen and (min-width: 768px)
  .page-wrapper.toggled .page-content
    padding-left: 260px

  /*----------------show sidebar button----------------*/
#show-sidebar
  position: fixed
  left: 0
  top: 10px
  border-radius: 0 4px 4px 0px
  width: 35px
  transition-delay: 0.3s

.page-wrapper.toggled #show-sidebar
  left: -40px

  /*----------------sidebar-wrapper----------------*/

.sidebar-wrapper
  width: 260px
  height: 100%
  max-height: 100%
  position: fixed
  top: 0
  left: -300px
  z-index: 999

.sidebar-wrapper ul
  list-style-type: none
  padding: 0
  margin: 0

.sidebar-wrapper a
  text-decoration: none

  /*----------------sidebar-content----------------*/

.sidebar-content
  max-height: calc(100% - 30px)
  height: calc(100% - 30px)
  overflow-y: auto
  position: relative

.sidebar-content.desktop
  overflow-y: hidden

  /*--------------------sidebar-brand----------------------*/

.sidebar-wrapper .sidebar-brand
  padding: 10px 20px
  display: flex
  align-items: center

.sidebar-wrapper .sidebar-brand > a
  text-transform: uppercase
  font-weight: bold
  flex-grow: 1

.sidebar-wrapper .sidebar-brand #close-sidebar
  cursor: pointer
  font-size: 20px

  /*--------------------sidebar-header----------------------*/

.sidebar-wrapper .sidebar-header
  padding: 20px
  overflow: hidden

.sidebar-wrapper .sidebar-header .user-pic
  float: left
  width: 60px
  max-height: 60px
  padding: 2px
  border-radius: 12px
  margin-right: 15px
  overflow: hidden

.sidebar-wrapper .sidebar-header .user-pic img
  border-radius: 50%
  border: 1px solid white
  object-fit: cover
  width: 100%

.sidebar-wrapper .sidebar-header .user-info
  float: left
  width: calc(100% - 75px)
  height: 60px
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column

.sidebar-wrapper .sidebar-header .user-info > span
  display: block

.sidebar-wrapper .sidebar-header .user-info .user-role
  font-size: 12px

.sidebar-wrapper .sidebar-header .user-info .user-status
  font-size: 11px
  margin-top: 4px

.sidebar-wrapper .sidebar-header .user-info .user-status i
  font-size: 8px
  margin-right: 4px
  color: #5cb85c

.sidebar-wrapper .sidebar-search > div
  padding: 10px 20px

.sidebar-wrapper .sidebar-menu
  padding-bottom: 10px

.sidebar-wrapper .sidebar-menu .header-menu span
  font-weight: bold
  font-size: 14px
  padding: 15px 20px 5px 20px
  display: inline-block

.sidebar-wrapper .sidebar-menu ul li a
  display: inline-block
  width: 100%
  text-decoration: none
  position: relative
  padding: 8px 30px 8px 20px

.sidebar-wrapper .sidebar-menu ul li a i
  margin-right: 10px
  font-size: 12px
  width: 30px
  height: 30px
  line-height: 30px
  text-align: center
  border-radius: 4px

.sidebar-wrapper .sidebar-menu ul li a:hover > i::before
  display: inline-block
  animation: swing ease-in-out 0.5s 1 alternate

.sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after
  font-family: 'Font Awesome 5 Free'
  font-weight: 900
  content: '\f105'
  font-style: normal
  display: inline-block
  font-style: normal
  font-variant: normal
  text-rendering: auto
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  background: 0 0
  position: absolute
  right: 15px
  top: 14px

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul
  padding: 5px 0

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li
  padding-left: 25px
  font-size: 13px

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before
  content: '\f111'
  font-family: 'Font Awesome 5 Free'
  font-weight: 400
  font-style: normal
  display: inline-block
  text-align: center
  text-decoration: none
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  margin-right: 10px
  font-size: 8px

.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge
  float: right
  margin-top: 8px
  margin-left: 5px

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label
  float: right
  margin-top: 0px

.sidebar-wrapper .sidebar-menu .sidebar-submenu
  display: none

.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after
  transform: rotate(90deg)
  right: 17px

.sidebar-footer
  position: absolute
  width: 100%
  bottom: 0
  display: flex

.sidebar-footer > a
  flex-grow: 1
  text-align: center
  height: 30px
  line-height: 30px
  position: relative

.sidebar-footer > a .notification
  position: absolute
  top: 0

.badge-sonar
  display: inline-block
  background: #980303
  border-radius: 50%
  height: 8px
  width: 8px
  position: absolute
  top: 0

.badge-sonar:after
  content: ''
  position: absolute
  top: 0
  left: 0
  border: 2px solid #980303
  opacity: 0
  border-radius: 50%
  width: 100%
  height: 100%
  animation: sonar 1.5s infinite

.page-wrapper .page-content
  display: inline-block
  width: 100%
  padding-left: 0px

.page-wrapper .page-content
  overflow-x: hidden

::-webkit-scrollbar
  width: 5px
  height: 7px

::-webkit-scrollbar-button
  width: 0px
  height: 0px

::-webkit-scrollbar-thumb
  background: #525965
  border: 0px none #ffffff
  border-radius: 0px

::-webkit-scrollbar-thumb:hover
  background: #525965

::-webkit-scrollbar-thumb:active
  background: #525965

::-webkit-scrollbar-track
  background: transparent
  border: 0px none #ffffff
  border-radius: 50px

::-webkit-scrollbar-track:hover
  background: transparent

::-webkit-scrollbar-track:active
  background: transparent

::-webkit-scrollbar-corner
  background: transparent

  /*-----------------------------chiller-theme-------------------------------------------------*/

.chiller-theme-bg
  background: #32323a

.chiller-theme .sidebar-wrapper
  background: #32323a

.chiller-theme .sidebar-wrapper .sidebar-header,
.chiller-theme .sidebar-wrapper .sidebar-search,
.chiller-theme .sidebar-wrapper .sidebar-menu
  border-top: 1px solid #4a4a52

input.search-menu,.input-group-text
  border-color: transparent
  box-shadow: none

.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
input.search-menu,.input-group-text,
.chiller-theme .sidebar-wrapper .sidebar-brand>a,
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a,
.chiller-theme .sidebar-footer>a
  color: #b0b3b8

.chiller-theme .sidebar-wrapper .sidebar-menu ul li:hover>a,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info,
.chiller-theme .sidebar-wrapper .sidebar-brand>a:hover,
.chiller-theme .sidebar-footer>a:hover i
  color: #b0b3b8

.sidebar-footer-a:hover .fa-power-off.sidebar-footer-a-i
  color: #dadada!important

.page-wrapper.chiller-theme.toggled #close-sidebar
  color: #fff

.page-wrapper.chiller-theme.toggled #close-sidebar:hover
  color: #000

.chiller-theme .sidebar-wrapper ul li:hover a i,
.chiller-theme .sidebar-wrapper .sidebar-dropdown .sidebar-submenu li a:hover:before,
input.search-menu:focus+span,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i
  color: #16c7ff
  text-shadow: 0px 0px 10px rgba(22, 199, 255, 0.5)

input.search-menu:focus
  color: #000!important
  border-color: transparent!important
  box-shadow: 0 0 0 0.2rem rgba(0,0,0,0)!important

input.search-menu,.input-group-text
  background: #32323a!important

.chiller-theme .sidebar-wrapper .sidebar-menu ul li a i,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown div,
input.search-menu,.input-group-text
  background: #4a4a52

.chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span
  color: #b0b3b8

.chiller-theme .sidebar-footer
  background: #32323a
  box-shadow: 0px -1px 5px #32323a
  border-top: 1px solid #464a52

.chiller-theme .sidebar-footer>a:first-child
  border-left: none

.chiller-theme .sidebar-footer>a:last-child
  border-right: none

.sidebar-footer-a
  float: right
  display: flex

.sidebar-footer-a-i
  margin: auto

// main
.page-wrapper:not(.toggled) .app > div
  padding: 0 40

.cursor-pointer
  cursor: pointer

.app
  display: flex
  flex-direction: column
  height: 100vh
  overflow: overlay
  color: black

.app-content
  flex-grow: 3

//registration
.registration-page
  display: flex
  justify-content: center
  align-items: center
  height: 100vh

.registration-form
  padding: 3em
  border-radius: 8px
  border: 1px solid black

//login
.login-page
  display: flex
  justify-content: center
  align-items: center
  height: 100vh

.login-form
  padding: 3em
  border-radius: 8px
  border: 1px solid black

// TAB CONTENT
.tab-content
  .card
    border-radius: 0
    .card-header
      padding: 15px 16px
      border-radius: 0
      background-color: #f6f6f6
      h5
        margin: 0
        button
          display: block
          width: 100%
          padding: 0
          border: 0
          font-weight: 700
          color: rgba(0,0,0,.87)
          text-align: left
          white-space: normal

          &:hover,&:focus,&:active,&:hover:active
            text-decoration: none

    .card-body
      p
        color: #616161
        &:last-of-type
          margin: 0

// BORDER FIX
.accordion
  > .card
    &:not(:first-child)
      border-top: 0

.collapse.show
  .card-body
    border-bottom: 1px solid rgba(0,0,0,.125)

// fields-handle
.fields-handle
  height: 100%
  background: black
  position: absolute
  top: 0
  right: 0
  width: 10px

// table
.th-sm
  cursor: pointer
  position: relative
  padding-right: 30px!important

.th-sm.up::before
  opacity: 1

.th-sm::before
  position: absolute
  bottom: 1em
  display: block
  opacity: .3
  right: 1em
  content: '\f0de'
  font-family: 'Font Awesome\ 5 Free'
  font-weight: 900
  font-size: 1rem

.th-sm.down::after
  opacity: 1

.th-sm::after
  position: absolute
  bottom: 1em
  display: block
  opacity: .3
  content: '\f0dd'
  font-family: 'Font Awesome\ 5 Free'
  font-weight: 900
  right: 16px
  font-size: 1rem

// image modal
.iamge-wrapper
  z-index: 2000
  top: 0
  left: 0

.image-background
  background-color: #fff
  opacity: .7

.image-form
  overflow-y: scroll
  max-height: 80%
  min-height: 70%
  z-index: 2001

//date picker
.react-datepicker-wrapper
  width: 100%

// see modal
.see-wrapper
  z-index: 1000
  top: 0
  left: 0

.see-background
  background-color: #fff
  opacity: .7

.see-form
  overflow-y: overlay
  max-height: 80%
  z-index: 1001

// ticket form
.ticket-form
  height: calc(100% - 55px)

.ticket-textarea
  resize: none
  width: 100%
  height: 80px
  padding: 5px

.login-notification-textarea
  width: 100%
  padding: 5px
  min-height: 120px

.invisible-input
  opacity: 0
  width: 0
  height: 0

.switch-01

  .switch
    position: relative
    display: inline-block
    width: 60px
    height: 34px
    margin: 0 20px

    & input
      opacity: 0
      width: 0
      height: 0

      &:checked + .slider
        background-color: #2196F3

      &:focus + .slider
        box-shadow: 0 0 1px #2196F3

      &:checked + .slider:before
        -webkit-transform: translateX(26px)
        -ms-transform: translateX(26px)
        transform: translateX(26px)

  .slider
    position: absolute
    cursor: pointer
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: #ccc
    -webkit-transition: .4s
    transition: .4s

    &:before
      position: absolute
      content: ""
      height: 26px
      width: 26px
      left: 4px
      bottom: 4px
      background-color: white
      -webkit-transition: .4s
      transition: .4s

    &.round
      border-radius: 34px

    &.round:before
      border-radius: 50%

// info modal
.info-modal-wrapper
  z-index: 1000
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  align-items: center

  .info-modal-bg
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: #4c4c4c
    opacity: .6

  .info-modal
    z-index: 1
    max-width: 60%
    max-height: 60%
    background: white
    border-radius: 8px
    border: 1px solid black
    padding: 15px
    opacity: 1!important

    .info-modal-header
      width: 100%
      display: grid
      grid-template-columns: auto min-content
      grid-template-rows: auto

    .info-modal-content
      